import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Title, Text } from '@superlogica/design-ui'

import * as S from './styled'

const SectionAbout = () => (
  <S.SectionAbout>
    <Container>
      <S.InnerWrapper>
        <Row align="center">
          <Col xs={12} md={5}>
            <S.Figure>
              <StaticImage
                src="../../assets/images/home/bg-about-v2.png"
                objectFit="cover"
                objectPosition="center top"
                style={{ width: '100%', height: '100%' }}
                alt="Um homem sorrindo olhando para o computador"
              />
            </S.Figure>
          </Col>
          <Col xs={12} md={6} offset={{ xs: 0, md: 1 }}>
            <Block padding="60px 0" tag="article">
              <Title
                color="babar900"
                font="heading"
                size="display02"
                marginBottom={32}
                weight="bold"
                tag="h2"
              >
                O que é o Superlógica Acelera?
              </Title>
              <Text marginBottom={16}>
                É o nosso programa de capacitação gratuito para administradoras
                de condomínios.
              </Text>
              <Text marginBottom={16}>
                Dividido em módulos variados, que trarão muitas novidades em
                2025, os participantes terão acesso a conteúdos práticos e a uma
                plataforma de capacitação com materiais didáticos para promover
                a aceleração de seus negócios.
              </Text>
              <Text>
                Além disso, você recebe{' '}
                <strong>consultorias individuais personalizadas</strong>{' '}
                conduzidas por especialistas, garantindo um impulso estratégico
                que levará o seu negócio a outro patamar.
              </Text>
            </Block>
          </Col>
        </Row>
      </S.InnerWrapper>
    </Container>
  </S.SectionAbout>
)

export default SectionAbout
